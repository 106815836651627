import React from 'react'
import Layout from '../layouts/Layout'
import BasicHero from '../components/Hero/BasicHero'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Flex } from 'theme-ui'
import { Icon } from '../components/Icon'
import { Link } from 'gatsby'
import BackgroundImageHero from '../components/Hero/BackgroundImageHero'

const opportunities = [
  {
    title: 'Technology',
    description:
      'The culture within technology calls for nimble, creative, and strong problem solvers. Join a team of highly skilled professionals who are leading through innovation and blazing new opportunities and growth across the organization.',
    areas: [
      'Software Development',
      'Information Security',
      'Infrastructure',
      'Data',
    ],
    icon: 'wifi',
  },
  {
    title: 'Corporate',
    description:
      'The talented teams across our corporate functions are the foundation of our organization. Join a team of highly skilled professionals who enable the success and continued growth of our organization through their functional excellence and expertise.',
    areas: [
      'Accounting',
      'Human Resources',
      'Finance',
      'Credit Risk Analysis',
      'Legal',
      'Marketing',
    ],
    icon: 'corporate',
  },
  {
    title: 'Contact Center',
    description:
      "As a key component to customer service, our Contact Center is focused on creating a positive experience for both our customers and our employees. Join our team of enthusiastic, service-oriented professionals who make a difference in our customers' lives every day.",
    areas: ['Customer Service', 'Collections'],
    icon: 'chat',
  },
  {
    title: 'Retail US',
    description:
      "Join a team of enthusiastic, service-oriented professionals who make a difference in customer's lives every day.",
    areas: [
      'Consumer Loan Specialist',
      'Asst. Branch Manager',
      'Sr. Asst. Branch Manager',
      'Branch Manager',
      'District Manager',
      'Vice President of Operations',
    ],
    icon: 'shopping-cart',
  },
  {
    title: 'Retail Canada',
    description:
      "Join a team of enthusiastic, service-oriented professionals who make a difference in customer's lives every day.",
    areas: [
      'Financial Service Representative',
      'Asst. Branch Manager',
      'Branch Manager',
      'District Manager',
      'Vice President of Operations',
    ],
    icon: 'shopping-cart',
  },
]

function Careers() {
  return (
    <Layout theme="light">
      <BackgroundImageHero
        theme="light"
        heading="Attain Finance<br/> Careers"
        titleSubHeading="Get Curious"
        cta={
          <a
            href="https://curobrands-curo.icims.com/jobs/intro?hashed=-435596295&mobile=true&width=440&height=760&bga=true&needsRedirect=false&jan1offset=-300&jun1offset=-240"
            sx={{
              variant: 'buttons.secondary',
              mt: [0, null, 5],
              width: 150,
              justifySelf: ['center', null, 'flex-start'],
            }}
          >
            Apply Today
          </a>
        }
        images={{
          mobile: '../images/hero/Career_Mobile.jpg',
          desktop: '../images/hero/Careers.jpg',
        }}
      />

      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            alignItems: 'center',
          }}
        >
          <h2
            sx={{
              width: ['100%', null, null, '50%'],
              textAlign: ['left'],
              pb: [3, null, null, 0],
            }}
          >
            Welcome to <br /> Attain Finance Careers
          </h2>
          <p
            sx={{
              width: ['100%', null, null, '50%'],
            }}
          >
            Attain Finance serves the evolving needs of the financial consumer.
            Our unique, fully integrated system for end-to-end ownership of our
            financial and loan transactions is unmatched in the industry.
            <br /> <br /> Known as an innovative, fast-paced, people-centric
            organization, we couldn’t be more excited about the future. We
            invite you to get curious about all Attain Finance has to offer and
            to explore our many exciting career paths!
          </p>
        </Flex>
      </Container>
      <section
        sx={{
          bg: '#f1f2f2',
        }}
      >
        <Container sx={{ flexDirection: 'column' }}>
          <h2 sx={{ mb: 3 }}>Opportunities</h2>
          {opportunities.map((item, index) => (
            <Flex
              sx={{
                borderBottom:
                  index < opportunities.length - 1
                    ? 'solid 1px #707070'
                    : 'none',
                maxWidth: 1080,
                justifyContent: ['center', 'flex-start'],
                alignItems: ['flex-start', 'center'],
                flexDirection: ['column', null, null, 'row'],
                py: 5,
              }}
            >
              <Flex
                sx={{
                  bg: 'brand.veniceBlue',
                  color: 'white',
                  flexDirection: 'column',
                  py: 50,
                  px: 33,
                  maxWidth: ['100%', 500, null, null, 780],
                }}
              >
                <Icon
                  name={item.icon}
                  size={50}
                  sx={{
                    mb: 3,
                  }}
                />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  pl: 4,
                  gap: 2,
                  mt: [5, null, null, 0],
                }}
              >
                {item.areas.map((area, index) => (
                  <Flex
                    key={index}
                    sx={{
                      alignItems: 'center',
                      color: 'brand.veniceBlue',
                    }}
                  >
                    <Icon
                      name="check-mark"
                      size={16}
                      sx={{
                        mr: 2,
                      }}
                    />{' '}
                    {area}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
          <a
            href="https://curobrands-curo.icims.com/jobs/intro?hashed=-435596295&mobile=true&width=440&height=760&bga=true&needsRedirect=false&jan1offset=-300&jun1offset=-240"
            target="_blank"
            sx={{
              variant: 'buttons.primary',
              alignSelf: ['center', null, null, 'flex-start'],
              mt: 4,
            }}
          >
            Apply Today
          </a>
        </Container>
      </section>
      <section
        sx={{
          bg: 'brand.veniceBlue',
          color: 'white',
        }}
      >
        <Container
          sx={{
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <h2
            sx={{
              minWidth: ['100%', null, null, 400],
              mb: [5, null, null, 0],
            }}
          >
            Benefits
          </h2>
          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: ['center', null, null, 'flex-start'],
              gap: [3, null, null, 4],
              '.box': {
                maxWidth: [150, null, null, 208],
                justifyContent: ['center', null, null, 'flex-start'],
                alignItems: ['center', null, null, 'flex-start'],
                p: {
                  textAlign: ['center', null, null, 'left'],
                },
              },
            }}
          >
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
              }}
            >
              <Icon name="health-kit" size={36} sx={{ mb: 3 }} />
              <p
                sx={{
                  fontSize: 18,
                }}
              >
                Competitive health benefits
              </p>
            </Flex>
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
              }}
            >
              <Icon name="edit" size={36} sx={{ mb: 3 }} />
              <p
                sx={{
                  fontSize: 18,
                }}
              >
                World class training and development
              </p>
            </Flex>
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
              }}
            >
              <Icon name="arrow" size={36} sx={{ mb: 3 }} />
              <p
                sx={{
                  fontSize: 18,
                }}
              >
                Paid time off and holidays
              </p>
            </Flex>
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
              }}
            >
              <Icon name="star" size={36} sx={{ mb: 3 }} />
              <p
                sx={{
                  fontSize: 18,
                }}
              >
                Performance based merit
              </p>
            </Flex>
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
              }}
            >
              <Icon name="coins" size={36} sx={{ mb: 3 }} />
              <p
                sx={{
                  fontSize: 18,
                }}
              >
                Company match toward retirement
                <sup
                  sx={{
                    color: 'brand.curiousBlue',
                  }}
                >
                  *
                </sup>
              </p>
            </Flex>
            <Flex
              className="box"
              sx={{
                flexDirection: 'column',
                alignSelf: 'flex-end',
              }}
            >
              <p
                sx={{
                  fontSize: 12,
                  lineHeight: 1.5,
                }}
              >
                <sup
                  sx={{
                    color: 'brand.curiousBlue',
                    fontSize: 14,
                  }}
                >
                  *
                </sup>
                Company provides contribution matches to full-time employees
                retirement fund in the US and pension in Canada.
              </p>
            </Flex>
          </Flex>
        </Container>
      </section>
      {/* <section
        sx={{
          bg: 'white',
        }}
      >
        <Container
          sx={{
            flexDirection: ['column'],
            p: {
              fontWeight: 300,
            },
            h2: {
              fontSize: 38,
            },
            h3: {
              fontSize: 18,
            },
          }}
        >
          <h2
            sx={{
              textAlign: 'left',
            }}
          >
            Diversity and Inclusion
          </h2>
          <p
            sx={{
              maxWidth: 740,
              pb: [3, null, null, 5],
              fontSize: 18,
            }}
          >
            At Attain Finance, we care for our people: our customers, our
            employees, our communities. Our commitment-in-action to drive
            employee engagement and retention includes supporting:
          </p>
          <Flex
            sx={{
              flexDirection: ['column', null, null, 'row'],
              gap: [4, null, null, 0],
              pt: [3, null, null, 0],
            }}
          >
            <div>
              <h3>Diversity and inclusion</h3>
              <p>
                By committing to a diverse workplace inclusive of differences
                and mental health through awareness, education, engagement,
                open-door policies, and everyday standard procedures.
              </p>
            </div>
            <div>
              <h3>Volunteering</h3>
              <p>
                By maintaining a culture that encourages volunteering to foster
                positive internal and external relations in the communities
                where we live and work.
              </p>
            </div>
            <div>
              <h3>Giving</h3>
              <p>
                By empowering our employees to start grassroots,
                company-supported charitable projects to create the most
                positive impact for that community.
              </p>
            </div>
          </Flex>
        </Container>
      </section> */}
    </Layout>
  )
}

export default Careers
