import React from 'react'
import { Flex } from 'theme-ui'

interface Props {
  bgColor?: string
  images: {
    desktop: string
    mobile: string
  }
  heading: string
  subHeading?: string
  titleSubHeading?: string
  cta?: any
  theme?: 'dark' | 'light'
}

const BackgroundImageHero = ({
  bgColor,
  images,
  heading,
  subHeading,
  theme,
  titleSubHeading,
  cta,
}: Props) => {
  return (
    <Flex
      sx={{
        bg: bgColor,
        backgroundImage: [
          `url(${images.mobile})`,
          null,

          `url(${images.desktop})`,
        ],
        backgroundSize: ['cover'],
        backgroundPosition: ['center'],
        width: '100%',
        height: [845, 945, 600, null, 600, 700],
        justifyContent: 'center',
        alignItems: ['center'],
      }}
    >
      <div
        sx={{
          maxWidth: ['100%', null, null, 1100],
          width: '100%',
          px: 4,
        }}
      >
        <h1
          sx={{
            width: ['100%', null, '488px'],
            mt: [!subHeading ? -250 : -100, -180, 0, 0, 0],
            position: 'relative',
            color: theme === 'light' ? 'black' : 'white',
            textAlign: ['center', null, 'left'],
          }}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        {subHeading && (
          <p
            sx={{
              maxWidth: ['100%', null, 480],
              lineHeight: '25px',
              fontSize: 18,
              textAlign: ['center', null, 'left'],
              color: theme === 'light' ? 'black' : 'white',
            }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}
        {titleSubHeading && (
          <p
            sx={{
              maxWidth: 480,
              lineHeight: '25px',
              fontSize: 22,
              pb: [cta ? 2 : 5, null, null, 0],
              textAlign: ['center', null, 'left'],

              color: 'brand.curiousBlue',
            }}
            dangerouslySetInnerHTML={{ __html: titleSubHeading }}
          />
        )}
        {cta && cta}
      </div>
    </Flex>
  )
}

export default BackgroundImageHero
